@charset "UTF-8";
/* section Login */
.login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.login__left {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    height: 100%;
    padding-top: 6px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.login-right {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    position: relative;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    margin-left: auto;
}

.login-logo {
    padding-top: 100px;
}

.login-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 112px;
    /* or 280% */
    letter-spacing: -1.5px;
    padding-top: 40px;
    color: #000000;
}

.login-form {
    padding-top: 10px;
}

@media (max-width: 768px) {
    .home-block {
        min-height: 500px;
    }

    .home__heading {
        padding-top: 89px;
    }
}

@media (max-width: 410px) {
    .home__heading {
        padding-top: 40px;
    }
}

.r-img {
    padding-top: 3px;
    padding-left: 3px;
}

.r-text {
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.15px;
    /* text/secondary */
    color: rgba(0, 0, 0, 0.6);
}

.request-label {
    /* Auto layout */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0px 12px;
    gap: 10px;
    width: 375px;
    height: 55px;
    /* other/filled-input-background */
    background: rgba(0, 0, 0, 0.09);
    border-radius: 4px 4px 0px 0px;
}

.request-input {
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.15px;
    /* text/primary */
    color: rgba(0, 0, 0, 0.87);
    background-color: #e8e8e8
}

.r-email {
    width: 351px;
    height: 24px;
}

.r-password {
    width: 319px;
    height: 24px;
}

.border-error {
    border: 1px solid #CB3D40;
}

.request li {
    padding-top: 50px;
    margin: 0 auto;
    /*центрируем ее*/
}

.request-grid {
    display: inline-grid;
}

.request-grid-label {
    text-align: left;
    padding-top: 4px;
    padding-left: 16px;
    color: #7E7E7E;
    font-size: 12px;
    line-height: 14px;
}

.text-error {
    color: #CB3D40;
}

.request__btn {
    /* Auto layout */
    display: inline-grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 50px;
}

.request-btn {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    /* identical to box height, or 173% */
    letter-spacing: 0.46px;
    text-transform: uppercase;
    /* secondary/contrast */
    color: #FFFFFF;
    width: 375px;
    height: 42px;
    display: inline-block;
    background: #F50057;
    /* elevation/2 */
    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.request-btn:hover {
    opacity: 0.8;
}

